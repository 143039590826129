import React from 'react'
import styled from 'styled-components'
import WhatsAppImage from '../public/whatsapp.svg'
    
export default function WhatsappButton() {
  return (
    <Container href='https://api.whatsapp.com/send?phone=+5521999295467&text=Ol%C3%A1%2C+estou+usando+a+plataforma+Meu+Nascimento+e+preciso+de+ajuda...' target='_blank'>
        <img src={WhatsAppImage}/>
    </Container>
  )
}


const Container = styled.a`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: #128C7E;
    box-shadow: 0 0 12px 12px rgba(0,0,0,0.12);
    cursor: pointer;
    z-index: 9999;

    img{
        width: 70px;
    }

    @media (max-width: 992px){
        width: 50px;
        height: 50px;
        bottom: 16px;
        right: 16px;

        img{
            width: 30px;
        }
    }
`
